<template>
  <vx-card title="View Route Plan">
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Code</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-input :value="code" :readonly="true"></vs-input>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="selectedTerritory"
          :options="optionTerritory"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="100"
          :limit="3"
          :searchable="true"
          :disabled="true"
          placeholder="Type to search"
          :custom-label="customLabel"
        />
      </div>
    </div>

    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Zone</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="selectedZone"
          :options="optionZone"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="100"
          :limit="3"
          :searchable="true"
          :disabled="true"
          placeholder="Type to search"
          :custom-label="customLabel"
        />
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Route Group</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <multiselect
          class="selectExample"
          v-model="selectedRouteGroup"
          :options="optionRouteGroup"
          :multiple="false"
          :allow-empty="true"
          :group-select="false"
          :max-height="100"
          :limit="3"
          :searchable="true"
          :disabled="true"
          placeholder="Type to search"
          label="name"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.code }}</span>
            </span>
          </template>
          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.code }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div v-if="this.selectedRouteGroup">
      <div
        class="vx-row mb-6"
        style="width: 50%"
        v-if="this.selectedRouteGroup.code == 'Monthly'"
      >
        <div class="vx-col sm:w-1/3 w-full flex items-center">
          <span>Month</span>
        </div>
        <div class="vx-col sm:w-2/3 w-full">
          <datepicker
            name="month"
            format="MMM yyyy"
            :inline="false"
            v-model="month"
            minimum-view="month"
            placeholder="Select Month"
            :disabled="true"
          ></datepicker>
        </div>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Description</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <vs-textarea v-model="description" width="100%" :disabled="true" />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <l-map style="height: 400px" :zoom="zoom" :center="center">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-marker
            :lat-lng="customerAddress.latLng"
            :key="index"
            v-for="(customerAddress, index) in customerAddresses"
          >
            <l-tooltip :options="{ permanent: true, interactive: true }">
              <div>
                <b>{{ customerAddress.label }}</b
                ><br />{{ customerAddress.address }}
              </div>
            </l-tooltip>
          </l-marker>
        </l-map>
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mb-base">
        <vs-table :data="this.optionRouteGroupLine" :border="1">
          <template slot="thead">
            <!-- <vs-th></vs-th> -->
            <vs-th width="200px">Route Schedule</vs-th>
            <vs-th width="20px">No</vs-th>
            <vs-th width="400px">Customer</vs-th>
            <vs-th width="100px">Dstr Channel</vs-th>
            <vs-th width="400px">Customer Ship-to</vs-th>
            <vs-th width="400px">Ship-to Address</vs-th>
            <!-- <vs-th width="50px"></vs-th>
            <vs-th width="75px"></vs-th> -->
          </template>
          <template slot-scope="{ data }">
            <tr :data="tr" :key="indexParent" v-for="(tr, indexParent) in data">
              <td class="td vs-table--td" width="200px">
                {{ data[indexParent].code }}
              </td>
              <td colspan="6">
                <table>
                  <draggable
                    :list="data[indexParent].customer_lines"
                    tag="tbody"
                  >
                    <tr
                      v-for="(child, indexChild) in data[indexParent]
                        .customer_lines"
                      :key="indexChild"
                    >
                      <td class="td vs-table--td" width="20px">
                        {{ indexChild + 1 }}
                      </td>
                      <td class="td vs-table--td" width="400px">
                        <v-select
                          :disabled="true"
                          label="id"
                          :filterable="false"
                          :options="
                            data[indexParent].customer_lines[indexChild]
                              .option_customer
                          "
                          v-model="
                            data[indexParent].customer_lines[indexChild]
                              .selected_customer
                          "
                          @input="
                            (val) => {
                              onChangeCustomer(val, indexParent, indexChild);
                            }
                          "
                          @search="
                            (search, loading) => {
                              onSearchCustomer(
                                search,
                                loading,
                                indexParent,
                                indexChild
                              );
                            }
                          "
                        >
                          <template slot="option" slot-scope="option">
                            {{ option.code }} {{ option.name }}
                          </template>
                          <template slot="selected-option" slot-scope="option">
                            {{ option.code }} {{ option.name }}
                          </template>
                        </v-select>
                      </td>
                      <td width="100px">
                        <input
                          type="text"
                          readonly="readonly"
                          class="vs-inputx vs-input--input normal"
                          style="border: 1px solid rgba(0, 0, 0, 0.2)"
                          :value="
                            getCustomerCategoryName(
                              data[indexParent].customer_lines[indexChild]
                                .selected_customer
                            )
                          "
                        />
                      </td>
                      <td width="400px">
                        <v-select
                          :disabled="true"
                          label="id"
                          :filterable="false"
                          :options="
                            data[indexParent].customer_lines[indexChild]
                              .option_customer_address
                          "
                          v-model="
                            data[indexParent].customer_lines[indexChild]
                              .selected_customer_address
                          "
                          @input="
                            (val) => {
                              onChangeCustomerAddress(
                                val,
                                indexParent,
                                indexChild
                              );
                            }
                          "
                          @search="
                            (search, loading) => {
                              onSearchCustomerAddress(
                                search,
                                loading,
                                data[indexParent].customer_lines[indexChild]
                                  .selected_customer.id,
                                indexParent,
                                indexChild
                              );
                            }
                          "
                        >
                          <template slot="option" slot-scope="option">
                            {{ option.code }} {{ option.contact_name }}
                          </template>
                          <template slot="selected-option" slot-scope="option">
                            {{ option.code }} {{ option.contact_name }}
                          </template>
                        </v-select>
                      </td>
                      <td width="400px">
                        <input
                          type="text"
                          readonly="readonly"
                          class="vs-inputx vs-input--input normal"
                          style="border: 1px solid rgba(0, 0, 0, 0.2)"
                          :value="
                            getCustomerAddressAddress(
                              data[indexParent].customer_lines[indexChild]
                                .selected_customer_address
                            )
                          "
                        />
                      </td>
                      <!-- <td
                        width="50px"
                        class="td vs-table--td"
                        style="text-align: center"
                      >
                        <arrow-up-down-bold :size="15" />
                      </td>
                      <td
                        width="75px"
                        class="td vs-table--td"
                        style="text-align: center"
                      >
                        <div class="vx-input-group flex">
                          <vs-button
                            v-if="data[indexParent].customer_lines.length > 1"
                            @click.stop="
                              removeCustomerRow(indexParent, indexChild)
                            "
                            size="small"
                            color="danger"
                            icon-pack="feather"
                            icon="icon-trash"
                            style="margin-right: 5px"
                            title="Remove Customer"
                          />

                          <vs-button
                            v-if="
                              data[indexParent].customer_lines.length - 1 ==
                              indexChild
                            "
                            @click.stop="addCustomerRow(indexParent)"
                            size="small"
                            color="success"
                            icon-pack="feather"
                            icon="icon-plus"
                            title="Add Customer"
                          />
                        </div>
                      </td> -->
                    </tr>
                  </draggable>
                </table>
              </td>
            </tr>
          </template>
        </vs-table>
      </div>
    </div>
    <!-- <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/1 w-full">
        <div
          style="
            position: absolute;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          "
        >
          <vs-button class="mr-3 mb-2" @click="handleSubmit">Submit</vs-button>
          <vs-button
            class="ml-4 mt-2"
            type="border"
            color="danger"
            v-on:click="handleBack"
            >Back</vs-button
          >
        </div>
      </div>
    </div> -->
  </vx-card>
</template>
<script>
import ArrowUpDownBold from "vue-material-design-icons/ArrowUpDownBold.vue";
import PlusBox from "vue-material-design-icons/PlusBox.vue";

import draggable from "vuedraggable";
import _ from "lodash";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import moment from "moment";

import "leaflet/dist/leaflet.css";

import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";

import { Icon } from "leaflet";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

export default {
  components: {
    draggable,
    ArrowUpDownBold,
    PlusBox,
    vSelect,
    Datepicker,
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/sfa/v1/route-plan",
        optionTerritory: [],
        selectedTerritory: null,
        optionZone: [],
        selectedZone: null,
        optionRouteGroup: [],
        selectedRouteGroup: null,
        code: "",
        description: "",
        optionRouteGroupLine: [],
        selectedRouteGroupLine: null,
        optionCustomer: [],
        month: null,
        routePlanID: null,

        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        zoom: 15,
        center: [-6.2, 106.816666],
        customerAddresses: [],
      };
    },
    handleBack() {
      this.$router.push({
        name: "route-plan",
      });
    },
    handleSubmit() {
      let cek = this.optionRouteGroupLine.filter((el) => {
        let cek1 = el.customer_lines.filter((el) => {
          if (el.customer_id && !el.customer_address_id) {
            return true;
          }
          return false;
        });

        if (cek1.length > 0) {
          return true;
        }
        return false;
      });

      if (cek.length > 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Customer ship-to address is required in filled customer",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      } else {
        let routePlanLines = [];
        this.optionRouteGroupLine.forEach((el) => {
          let customerLines = [];
          el.customer_lines.forEach((customerLine, index) => {
            if (customerLine.customer_id && customerLine.customer_address_id) {
              customerLines.push({
                customer_id: customerLine.customer_id,
                customer_address_id: customerLine.customer_address_id,
                sequence: index + 1,
              });
            }
          });

          if (customerLines.length > 0) {
            if (this.selectedRouteGroup.code != "Monthly") {
              routePlanLines.push({
                route_group_line_id: el.id,
                customer_lines: customerLines,
              });
            } else {
              routePlanLines.push({
                route_group_line_id: el.id,
                date: moment(el.code).format("YYYY-MM-DD"),
                customer_lines: customerLines,
              });
            }
          }
        });

        if (routePlanLines.length == 0) {
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "No data to create",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
        } else {
          this.$validator.validateAll().then((result) => {
            if (result) {
              this.$vs.loading();
              this.$http
                .put(this.baseUrl + "/" + this.routePlanID + "/update", {
                  territory_id: this.selectedTerritory.id,
                  zone_id: this.selectedZone ? this.selectedZone.id : null,
                  route_group_id: this.selectedRouteGroup.id,
                  date: this.month
                    ? moment(this.month).format("YYYY-MM-DD")
                    : null,
                  description: this.description,
                  route_plan_lines: routePlanLines,
                  status: "Draft",
                })
                .then((resp) => {
                  if (resp.code == 200) {
                    this.$vs.notify({
                      color: "success",
                      title: "Success",
                      text: resp.message,
                      position: "top-right",
                      iconPack: "feather",
                      icon: "icon-x-circle",
                    });

                    this.$vs.loading.close();
                    setTimeout(this.handleBack(), 2000);
                  } else {
                    this.$vs.loading.close();
                    this.$vs.notify({
                      color: "danger",
                      title: "Error",
                      text: resp.message,
                      position: "top-right",
                      iconPack: "feather",
                      icon: "icon-x-circle",
                    });
                  }
                })
                .catch((error) => {
                  this.$vs.loading.close();
                  console.log(error);
                });
            }
          });
        }
      }
    },
    customLabel(data) {
      return data.code + " " + data.name;
    },
    onChangeCustomer(val, indexParent, indexChild) {
      // let cek = this.optionRouteGroupLine[indexParent].customer_lines.filter(
      //   (el, index) => {
      //     if (el.selected_customer && val) {
      //       if (el.selected_customer.id == val.id && index != indexChild) {
      //         return true;
      //       }
      //     }
      //     return false;
      //   }
      // );

      //  if (cek.length > 0) {
      //   alert("Customer already add");
      //   this.optionRouteGroupLine[indexParent].customer_lines[
      //     indexChild
      //   ].selected_customer = null;
      //   return false;
      // } else {
      //   this.optionRouteGroupLine[indexParent].customer_lines[
      //     indexChild
      //   ].selected_customer = val;

      //   this.setCustomerAddress(
      //     this.optionRouteGroupLine[indexParent].customer_lines[indexChild],
      //     indexParent,
      //     indexChild
      //   );
      // }

      this.optionRouteGroupLine[indexParent].customer_lines[
        indexChild
      ].selected_customer = val;

      this.setCustomerAddress(
        this.optionRouteGroupLine[indexParent].customer_lines[indexChild],
        indexParent,
        indexChild
      );
    },
    onChangeCustomerAddress(val, indexParent, indexChild) {
      let cek = this.optionRouteGroupLine[indexParent].customer_lines.filter(
        (el, index) => {
          if (el.selected_customer_address && val) {
            if (
              el.selected_customer_address.id == val.id &&
              index != indexChild
            ) {
              return true;
            }
          }
          return false;
        }
      );

      if (cek.length > 0) {
        alert("Customer ship-to address already add");
        this.optionRouteGroupLine[indexParent].customer_lines[
          indexChild
        ].selected_customer_address = null;

        this.optionRouteGroupLine[indexParent].customer_lines[
          indexChild
        ].customer_address_id = null;
      } else {
        this.optionRouteGroupLine[indexParent].customer_lines[
          indexChild
        ].selected_customer_address = val;

        if (val) {
          this.optionRouteGroupLine[indexParent].customer_lines[
            indexChild
          ].customer_address_id = val.id;
        } else {
          this.optionRouteGroupLine[indexParent].customer_lines[
            indexChild
          ].customer_address_id = null;
        }
      }
    },

    addCustomerRow(indexParent) {
      this.optionRouteGroupLine[indexParent].customer_lines.push({
        customer_id: null,
        customer_address_id: null,
        selected_customer: null,
        option_customer: [],
        selected_customer_address: null,
        option_customer_address: [],
      });
    },
    removeCustomerRow(indexParent, indexChild) {
      this.optionRouteGroupLine[indexParent].customer_lines.splice(
        indexChild,
        1
      );
    },
    getCustomerCategoryName(selectedCustomer) {
      return selectedCustomer ? selectedCustomer.customer_category_name : "";
    },
    getCustomerAddressAddress(selectedCustomerAddress) {
      let address = "";
      if (selectedCustomerAddress) {
        if (selectedCustomerAddress.address) {
          address += selectedCustomerAddress.address;
        }

        if (selectedCustomerAddress.sub_district) {
          address += ", " + selectedCustomerAddress.sub_district;
        }

        if (selectedCustomerAddress.district) {
          address += ", " + selectedCustomerAddress.district;
        }

        if (selectedCustomerAddress.city) {
          address += ", " + selectedCustomerAddress.city;
        }

        if (selectedCustomerAddress.province) {
          address += ", " + selectedCustomerAddress.province;
        }

        if (selectedCustomerAddress.Indonesia) {
          address += ", " + selectedCustomerAddress.Indonesia;
        }

        if (selectedCustomerAddress.postal_code) {
          address += " " + selectedCustomerAddress.postal_code;
        }
      }

      return address;
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/territory", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = resp.data.records;
              if (this.optionTerritory.length > 0) {
                this.selectedTerritory = this.optionTerritory[0];
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = null;
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = null;
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getZone() {
      if (this.selectedTerritory) {
        if (this.selectedTerritory.code) {
          this.$vs.loading();
          this.$http
            .get(this.baseUrl + "/zone", {
              params: {
                order: "name",
                sort: "asc",
                territory_code: this.selectedTerritory.code,
              },
            })
            .then((resp) => {
              if (resp.code == 200) {
                if (resp.data.records) {
                  this.optionZone = resp.data.records;
                  if (this.optionZone.length > 0) {
                    this.selectedZone = this.optionZone[0];
                  } else {
                    this.optionZone = [];
                    this.selectedZone = {};
                  }
                } else {
                  this.optionZone = [];
                  this.selectedZone = {};
                }
                this.$vs.loading.close();
              } else {
                this.optionZone = [];
                this.selectedZone = {};
                this.$vs.loading.close();
              }
            });
        } else {
          this.optionZone = [];
          this.selectedZone = {};
        }
      }
    },
    getRouteGroup() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/route-group", {
          params: {
            order: "id",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionRouteGroup = resp.data.records;
              if (this.optionRouteGroup.length > 0) {
                this.selectedRouteGroup = this.optionRouteGroup[0];
              } else {
                this.optionRouteGroup = [];
                this.selectedRouteGroup = {};
              }
            } else {
              this.optionRouteGroup = [];
              this.selectedRouteGroup = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getRouteGroupLine() {
      if (this.selectedRouteGroup.id) {
        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/route-group-line", {
            params: {
              order: "id",
              sort: "asc",
              route_group_id: this.selectedRouteGroup.id,
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              if (resp.data.records) {
                let tempRuoteGroupLines = [];

                resp.data.records.forEach((element) => {
                  let customerLines = [];
                  customerLines.push({
                    customer_id: null,
                    customer_address_id: null,
                    selected_customer: null,
                    option_customer: [],
                    selected_customer_address: null,
                    option_customer_address: [],
                  });
                  element.customer_lines = customerLines;

                  tempRuoteGroupLines.push(element);
                });

                this.optionRouteGroupLine = tempRuoteGroupLines;

                if (this.optionRouteGroupLine.length > 0) {
                  this.selectedRouteGroupLine = this.optionRouteGroupLine[0];
                } else {
                  this.optionRouteGroupLine = [];
                  this.selectedRouteGroupLine = {};
                }
              } else {
                this.optionRouteGroupLine = [];
                this.selectedRouteGroupLine = {};
              }
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      }
    },
    getCustomer() {
      if (this.selectedTerritory) {
        this.$vs.loading();
        this.$http
          .get(this.baseUrl + "/customer", {
            params: {
              order: "id",
              sort: "asc",
              length: 30,
              territory_id: this.selectedTerritory.id,
              zone_id: this.selectedZone ? this.selectedZone.id : null,
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              if (resp.data.records) {
                this.optionCustomer = resp.data.records;
              } else {
                this.optionCustomer = [];
              }
              this.$vs.loading.close();
            } else {
              this.$vs.loading.close();
            }
          });
      }
    },
    getCustomerAddress(customerID) {
      if (this.selectedTerritory.id) {
        this.$http
          .get(this.baseUrl + "/customer-address", {
            params: {
              order: "id",
              sort: "asc",
              length: 10,
              territory_id: this.selectedTerritory.id,
              zone_id: this.selectedZone ? this.selectedZone.id : null,
              customer_id: customerID,
            },
          })
          .then((resp) => {
            if (resp.code == 200) {
              return resp.data.records;
            }
            return [];
          });
      }
    },

    setCustomerAddress(element, indexParent, indexChild) {
      if (element.selected_customer) {
        if (element.selected_customer.id) {
          if (element.selected_customer.id != element.customer_id) {
            if (this.selectedTerritory.id) {
              this.$http
                .get(this.baseUrl + "/customer-address", {
                  params: {
                    order: "id",
                    sort: "asc",
                    territory_id: this.selectedTerritory.id,
                    zone_id: this.selectedZone ? this.selectedZone.id : null,
                    customer_id: element.selected_customer.id,
                  },
                })
                .then((resp) => {
                  if (resp.code == 200) {
                    let customerAddresses = resp.data.records;
                    this.optionRouteGroupLine[indexParent].customer_lines[
                      indexChild
                    ].option_customer_address = customerAddresses;

                    if (customerAddresses.length > 0) {
                      var firstCustomerAddress = customerAddresses[0];
                      let cek = this.optionRouteGroupLine[
                        indexParent
                      ].customer_lines.filter((el, index) => {
                        if (
                          el.selected_customer_address &&
                          firstCustomerAddress
                        ) {
                          if (
                            el.selected_customer_address.id ==
                              firstCustomerAddress.id &&
                            index != indexChild
                          ) {
                            return true;
                          }
                        }
                        return false;
                      });

                      if (cek.length == 0) {
                        this.optionRouteGroupLine[indexParent].customer_lines[
                          indexChild
                        ].selected_customer_address = firstCustomerAddress;

                        this.optionRouteGroupLine[indexParent].customer_lines[
                          indexChild
                        ].customer_address_id = firstCustomerAddress.id;
                      }
                    }

                    this.optionRouteGroupLine[indexParent].customer_lines[
                      indexChild
                    ].customer_id = element.selected_customer.id;
                  } else {
                    this.resetSelectedCustomerAddress(indexParent, indexChild);
                  }
                });
            } else {
              this.resetSelectedCustomerAddress(indexParent, indexChild);
            }
          }
        } else {
          this.resetSelectedCustomerAddress(indexParent, indexChild);
        }
      } else {
        this.resetSelectedCustomerAddress(indexParent, indexChild);
      }
    },

    resetSelectedCustomerAddress(indexParent, indexChild) {
      this.optionRouteGroupLine[indexParent].customer_lines[
        indexChild
      ].option_customer_address = [];
      this.optionRouteGroupLine[indexParent].customer_lines[
        indexChild
      ].selected_customer_address = null;

      this.optionRouteGroupLine[indexParent].customer_lines[
        indexChild
      ].customer_id = null;
    },

    onSearchCustomer(search, loading, indexParent, indexChild) {
      if (search.length) {
        loading(true);
        this.searchCustomer(loading, search, this, indexParent, indexChild);
      }
    },
    onSearchCustomerAddress(
      search,
      loading,
      customerID,
      indexParent,
      indexChild
    ) {
      if (search.length) {
        loading(true);
        this.searchCustomerAddress(
          loading,
          search,
          this,
          customerID,
          indexParent,
          indexChild
        );
      }
    },
    searchCustomer: _.debounce(
      (loading, search, t, indexParent, indexChild) => {
        if (t.selectedTerritory) {
          t.$http
            .get(t.baseUrl + "/customer", {
              params: {
                order: "id",
                sort: "asc",
                length: 10,
                territory_id: t.selectedTerritory.id,
                zone_id: t.selectedZone ? t.selectedZone.id : null,
                search: search,
              },
            })
            .then((resp) => {
              if (resp.code == 200) {
                if (resp.data.records) {
                  t.optionRouteGroupLine[indexParent].customer_lines[
                    indexChild
                  ].option_customer = resp.data.records;
                } else {
                  t.optionRouteGroupLine[indexParent].customer_lines[
                    indexChild
                  ].option_customer = [];
                }
                loading(false);
              } else {
                t.optionRouteGroupLine[indexParent].customer_lines[
                  indexChild
                ].option_customer = [];
                loading(false);
              }
            });
        }
      },
      350
    ),
    searchCustomerAddress: _.debounce(
      (loading, search, t, customerID, indexParent, indexChild) => {
        if (t.selectedTerritory.id && customerID) {
          t.$http
            .get(t.baseUrl + "/customer-address", {
              params: {
                order: "id",
                sort: "asc",
                length: 10,
                territory_id: t.selectedTerritory.id,
                zone_id: t.selectedZone ? t.selectedZone.id : null,
                customer_id: customerID,
                search: search,
              },
            })
            .then((resp) => {
              if (resp.code == 200) {
                t.optionRouteGroupLine[indexParent].customer_lines[
                  indexChild
                ].option_customer_address = resp.data.records;
              } else {
                t.optionRouteGroupLine[indexParent].customer_lines[
                  indexChild
                ].option_customer_address = [];
              }
              loading(false);
            });
        }
      },
      350
    ),
    async getRouteGroupLine2(routeGroupID) {
      let resp = await this.$http.get(this.baseUrl + "/route-group-line", {
        params: {
          order: "id",
          sort: "asc",
          route_group_id: routeGroupID,
        },
      });

      if (resp.code == 200) {
        return resp.data.records;
      } else {
        return [];
      }
    },
    getRoutePlan(id) {
      let getAllDaysInMonth = this.getAllDaysInMonth;

      this.$http.get(this.baseUrl + "/" + id + "/edit").then((resp) => {
        if (resp.code == 200) {
          this.code = resp.data.code;
          this.description = resp.data.description;

          if (resp.data.schedule_type == "Monthly") {
            this.month = new Date(resp.data.date);
          }
          this.setSelectedTerritory(resp.data.territory_id);
          this.setSelectedZone(resp.data.zone_id);
          this.setSelectedRouteGroup(resp.data.route_group_id);

          this.getRouteGroupLine2(resp.data.route_group_id).then((orgl) => {
            if (orgl[0].route_group_code === "Monthly") {
              let tempRuoteGroupLines = [];
              let dates = getAllDaysInMonth(
                this.month.getFullYear(),
                this.month.getMonth()
              );
              dates.forEach((date) => {
                let customer_lines = [];
                if (resp.data.route_plan_lines) {
                  resp.data.route_plan_lines.forEach((data) => {
                    if (
                      moment(data.date).format("YYYY-MM-DD") ===
                      moment(date).format("YYYY-MM-DD")
                    ) {
                      data.customer_lines.forEach((d) => {
                        customer_lines.push({
                          customer_id: d.customer_id,
                          customer_address_id: d.customer_address_id,
                          selected_customer: d.selected_customer,
                          option_customer: [],
                          selected_customer_address:
                            d.selected_customer_address,
                          option_customer_address: [],
                        });
                      });
                    }
                  });
                }

                if (customer_lines.length == 0) {
                  customer_lines = [
                    {
                      customer_id: null,
                      customer_address_id: null,
                      selected_customer: null,
                      option_customer: [],
                      selected_customer_address: null,
                      option_customer_address: [],
                    },
                  ];
                }

                tempRuoteGroupLines.push({
                  code: moment(date).format("DD MMM YYYY"),
                  customer_lines: customer_lines,
                  day: "",
                  id: orgl[0].id,
                  name: orgl[0].name,
                  odd_even: "",
                  route_group_code: orgl[0].route_group_code,
                  route_group_name: orgl[0].route_group_name,
                });
              });
              this.optionRouteGroupLine = tempRuoteGroupLines;
            } else {
              orgl.forEach((routeGroupLine) => {
                let routeGroupLineData = [];
                if (resp.data.route_plan_lines) {
                  routeGroupLineData = resp.data.route_plan_lines.filter(
                    (el) => {
                      return el.route_group_line_id == routeGroupLine.id;
                    }
                  );
                }

                if (routeGroupLineData.length > 0) {
                  routeGroupLineData.forEach((cl) => {
                    let tempCustomerLines = [];
                    if (cl.customer_lines.length > 0) {
                      cl.customer_lines.forEach((el) => {
                        tempCustomerLines.push({
                          customer_id: el.customer_id,
                          customer_address_id: el.customer_address_id,
                          selected_customer: el.selected_customer,
                          option_customer: [],
                          selected_customer_address:
                            el.selected_customer_address,
                          option_customer_address: [],
                        });
                      });
                    } else {
                      tempCustomerLines.push({
                        customer_id: null,
                        customer_address_id: null,
                        selected_customer: null,
                        option_customer: [],
                        selected_customer_address: null,
                        option_customer_address: [],
                      });
                    }
                    routeGroupLine = Object.assign(routeGroupLine, {
                      customer_lines: tempCustomerLines,
                    });
                  });
                } else {
                  routeGroupLine = Object.assign(routeGroupLine, {
                    customer_lines: [
                      {
                        customer_id: null,
                        customer_address_id: null,
                        selected_customer: null,
                        option_customer: [],
                        selected_customer_address: null,
                        option_customer_address: [],
                      },
                    ],
                  });
                }
              });
              this.optionRouteGroupLine = orgl;
            }
          });
        }
      });
    },
    setSelectedTerritory(territorryID) {
      let cek = this.optionTerritory.filter((el) => {
        if (el.id == territorryID) {
          return true;
        }
        return false;
      });

      if (cek.length > 0) {
        this.selectedTerritory = cek[0];
      } else {
        this.selectedTerritory = null;
      }
    },
    setSelectedZone(zoneID) {
      let cek = this.optionZone.filter((el) => {
        if (el.id == zoneID) {
          return true;
        }
        return false;
      });

      if (cek.length > 0) {
        this.selectedZone = cek[0];
      } else {
        this.selectedZone = null;
      }
    },
    setSelectedRouteGroup(routeGroupID) {
      let cek = this.optionRouteGroup.filter((el) => {
        if (el.id == routeGroupID) {
          return true;
        }
        return false;
      });

      if (cek.length > 0) {
        this.selectedRouteGroup = cek[0];
      } else {
        this.selectedRouteGroup = null;
      }
    },
    getAllDaysInMonth(year, month) {
      const date = new Date(year, month, 1);
      const dates = [];
      while (date.getMonth() === month) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      return dates;
    },
  },
  created() {
    const success = (position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      // Do something with the position
      this.center = [latitude, longitude];
      // console.log(this.center);
    };

    const error = () => {
      console.log(error);
    };

    // This will open permission popup
    navigator.geolocation.getCurrentPosition(success, error);
  },
  mounted() {
    this.getTerritory();
    this.routePlanID = this.$route.params.id;
  },
  watch: {
    selectedTerritory() {
      this.getZone();
      this.getRouteGroup();
    },
    selectedZone() {
      // this.getCustomer();
    },
    selectedRouteGroup() {
      // this.getRouteGroupLine();

      this.getRoutePlan(this.routePlanID);
    },
    optionRouteGroupLine: {
      handler: function (val) {
        let customerAddresses = [];
        val.forEach((v) => {
          v.customer_lines.forEach((customer_line) => {
            if (customer_line.selected_customer_address) {
              if (
                customer_line.selected_customer_address.latitude &&
                customer_line.selected_customer_address.longitude
              ) {
                customerAddresses.push({
                  label:
                    customer_line.selected_customer_address.code +
                    " " +
                    customer_line.selected_customer_address.contact_name,
                  latLng: [
                    parseFloat(
                      customer_line.selected_customer_address.latitude
                    ),
                    parseFloat(
                      customer_line.selected_customer_address.longitude
                    ),
                  ],
                  address: this.getCustomerAddressAddress(
                    customer_line.selected_customer_address
                  ),
                });

                this.center = [
                  parseFloat(customer_line.selected_customer_address.latitude),
                  parseFloat(customer_line.selected_customer_address.longitude),
                ];
              }
            }
          });
        });

        this.customerAddresses = customerAddresses;
        // console.log(customerAddresses);
      },
      deep: true,
    },
  },
};
</script>

<style>
.multiselect__input {
  z-index: 0;
}

.multiselect__content-wrapper {
  z-index: 10;
}
</style>
